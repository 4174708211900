<script>
import EssayModal from '@/components/modals/essay-modal.vue';
import EssayViewModal from '@/components/modals/essay-view-modal.vue';
import EssayActionModal from '@/components/modals/essay-actions-modal.vue';
import EssayPdfViewModal from '../modals/essay-pdf-view-modal.vue';
import api from "@/helpers/api-rest/api.js";
import store from '@/state/store';

export default {
  components: { 
    EssayModal,
    EssayViewModal,
    EssayActionModal,
    EssayPdfViewModal
  },
  data() {
    return {
      essayModalId : 'ModalEssay',
      essayViewModalId: 'ModalViewEssay',
      essayActionModalId: 'ModalActionEssay',
      essayEdit: undefined,
      isBusy: false,
      totalItems: 0,
      isModalActive: false,
      currentStep: 0,
      listEssays: [],
      actionType: '',
      essayId: null,
      action: {},
      essayURL: ''
    }
  },
  props: {
    id:null,
    fields: {
      type: Array,
      required: true,
    },
    rows: {
      type: Number,
      required: true,
    },
  },
  mounted(){
    this.loadData();
    
  },
  methods: {
    showElement(roleId = [], show = true, showEdit = true) {
      const role = store.getters['auth/role'];
      if (roleId.includes(role.id) && show && showEdit) {
        return true;
      }
      return false;
    },
    showFinalStepIcon(essay, roleId = []) {
      const role = store.getters['auth/role'];
      return essay.current_step === 3 && roleId.includes(role.id);
    },
    async loadData() {
        this.isBusy = true;
        try {
          this.showElement();
          const apiEndpoint = `/api/v1/essays?project_id=${this.id}`;
          const response = await api.get({
              url:apiEndpoint,
          });
          // const data = response?.data?.data;
          const data = response?.data?.data.map(essay => ({
            ...essay,
            createdBy: essay.created_by.name,
            essayStatus: essay.essay_status.name,
            course_ids: essay.courses.map(item => item.id)
          }));

          this.totalItems = data.length;
          this.listEssays = data;
          this.isBusy = false;
          return data;
        } catch (error) {
            this.isBusy = false;
            return []
        }
    },
    success(essay) {
      console.log(essay);
    },
    viewEssay(essay) {
      this.essayEdit = essay;
      
      this.$bvModal.show(this.essayViewModalId);     
    },
    editEssay(essay) {
      if(!essay.essay_coordinator){
        essay.essay_coordinator = {
          center_id: null,
          essay_professorates: [],
          document_name: ''
        }
      }
      this.essayEdit = essay;
      this.$bvModal.show(this.essayModalId);
      this.isModalActive = true;
      this.currentStep = essay.current_step;
      
    },
    addEssay() {
      console.log("Abriendo modal...");
      this.essayEdit = {
        id: null,
        name: "",
        description: "",
        current_step: 0,
        course_ids: [],
        essay_coordinator: {
          center_id: null,
          document_name: null,
          document_path: null,
          essay_professorates: []
        },
        targets: [
          {
            target_results: []
          }
        ]
      };
      this.$bvModal.show(this.essayModalId);
      this.isModalActive = true;
      this.currentStep = 0;
    },
    viewPDF(){
      this.$bvModal.show('ModalViewPDF');
    },
    handleModalClose() {
      console.log("Cerrando modal tabla");
      this.$bvModal.hide(this.essayModalId);
      this.loadData();
      this.isModalActive = false;
    },
    onFiltered(filteredItems) {
      // `filteredItems` es un array de objetos que pasan el filtro
      // Puedes realizar acciones adicionales con los datos filtrados si es necesario
      console.log('Datos filtrados:', filteredItems);
      
      // Por ejemplo, puedes contar el número de elementos filtrados
      const numberOfFilteredItems = filteredItems.length;
      console.log('Número de elementos filtrados:', numberOfFilteredItems);
      
      // O calcular alguna estadística basada en los datos filtrados
      // ...

      // También puedes emitir un evento para informar al componente padre sobre los datos filtrados
      this.$emit('filtered-data', filteredItems);
    },
    handleModalAction(actionType, essay){
      this.$bvModal.hide(this.essayViewModalId);
      this.action = {
        description: '',
        essay_id: null
      }
      this.actionType = actionType;
      this.essayId = essay;
      this.$bvModal.show(this.essayActionModalId);
    },

    successAction(action){
      console.log(action);
      this.loadData();
    },

    setEssay(essay){
      this.essayEdit = essay;
      // if (essay.current_step === 3) {
      //   this.essayURL = essay.url;
      //   this.viewPDF();
      // }
    }
  },
};
</script>
<template>
  <div>
    <EssayModal :id="essayModalId" :project_id="id" :essay="essayEdit" :isActive="isModalActive" :currentStep="currentStep" @updatedEssay="setEssay" @success="success" @close="handleModalClose" />
    <EssayViewModal :id="essayViewModalId" :essay="essayEdit"  @open-action-modal="handleModalAction" />
    <EssayActionModal :id="essayActionModalId" :actionType="actionType" :essayId="essayId" :action="action" @success="successAction"/>
    <EssayPdfViewModal :url="essayURL"/>
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <button  v-if="showElement([3])" @click="addEssay()" class="btn btn-success mb-4">Redactar proyecto</button>
            <div class="row mt-2">
              <div class="col-sm-12 col-md-6">
                <h4 class="card-title">Lista de proyectos</h4>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="listEssays" :fields="fields" responsive="sm" :busy.sync="isBusy" show-empty>
                <template v-slot:cell(essayStatus)="row">
                  <div>
                    <div>
                      <b-badge pill :variant="row.item.essay_status_id === 3 ? 'warning' : (row.item.essay_status_id === 4 ? 'danger' : 'info')" class="ml-1">
                        {{ row.item.essayStatus }}
                      </b-badge>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(current_step)="row">
                  <div>
                    <div>
                      <b-badge pill :variant="row.item.current_step === 3 ? 'success' : 'warning'" class="ml-1">
                        {{ row.item.current_step === 3 ? 'Finalizado' : 'Incompleto (Paso ' + (row.item.current_step + 1) + ')' }}
                      </b-badge>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(action)="row">
                  <div>
                    <!-- Mostrar el icono de editar si el rol es 3 y current_step no es 3 y el essay_status es 3 -->
                    <a v-if="showElement([3], row.item?.current_step != 3, row.item?.essay_status_id != 3)" href="javascript:void(0);" class="mr-3 text-secondary" title="Edit" @click="editEssay(row.item)">
                      <i class="mdi mdi-square-edit-outline font-size-18"></i>
                    </a>
                    <a v-else-if="showElement([3], row.item?.current_step == 3, row.item?.essay_status_id == 3)" href="javascript:void(0);" class="mr-3 text-secondary" title="Edit" @click="editEssay(row.item)">
                      <i class="mdi mdi-square-edit-outline font-size-18"></i>
                    </a>
                    <!-- <a v-if="showElement([1, 3], row.item?.current_step == 3, row.item?.essay_status_id != 3)" href="javascript:void(0);" class="mr-3 text-secondary" title="View" @click="viewEssay(row.item)">
                      <i class="mdi mdi-eye-outline font-size-18"></i>
                    </a> -->
                    <!-- Mostrar el icono de vista si el rol es 1 y current_step es 3 y el essay_status no es 3 -->
                    <router-link v-if="showElement([1, 3], row.item?.current_step == 3, row.item?.essay_status_id != 3)" class="mr-3 text-secondary" :to="{ name: 'essay-detail', params: { id: id, essayId: row.item.id }}">
                      <i class="mdi mdi-eye-outline font-size-18"></i>
                    </router-link>
                  </div>
                </template>
              </b-table>
              <div v-if="isBusy" class="text-center text-success my-2 table-spinner">
                <b-spinner class="align-middle"></b-spinner>
              </div>
              <div v-else-if="totalItems == 0">
                <div class="text-center p-3">
                    <p>No hay registros</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
