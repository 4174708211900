<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import EssayTable from '@/components/tables/essays-table.vue';
import api from '@/helpers/api-rest/api.js';
import format from 'date-fns/format';
/**
 * FAQs component
 */
export default {
  props: ['id'],
  // page: {
  //   title: "Proyecto X",
  //   meta: [{ name: "description" }]
  // },
  components: { Layout, PageHeader, EssayTable },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Convocatorias",
          href: "/projects"
        },
        {
          text: "",
          active: true
        }
      ],
      tableFields: [
        { key: 'name', sortable: false, label: 'Nombre' },
        { key: 'createdBy', sortable: false, label: 'Usuario' },
        { 
          key: 'created_at', sortable: false, label: 'Fecha de creación', 
          formatter: (value) => {
            return format(new Date(value), 'dd/MM/yyyy');
          } 
        },
        { key: 'essayStatus', sortable: false, label: 'Estado' },
        { key: 'current_step', sortable: false, label: 'Redacción' },
        { key: "action", tdClass:"custom-table-action",  sortable: false, label: "" }
        // otros campos según tu modelo de datos
      ],
      project: {},
      essay: [],
      createdBy: null,
      loading: false,
    };
  },
  methods: {
    loadDataFromApi(){
      this.loading = true;
      const apiEndpoint = `/api/v1/projects/${this.id}`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.project = response?.data?.data;
          this.title = this.project.name;
          this.items[1].text = this.project.name
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.error("Error loading data:", error);
        });
    },
    
  },
  mounted() {
    this.loadDataFromApi();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="d-flex align-items-center">
                  <b-skeleton width="65%"></b-skeleton>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-12">
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex align-items-center">
                <h4 class="mb-0">
                    {{ project?.name }}
                </h4>
                <b-badge pill variant="info" class="ml-3">
                    {{ project?.project_type?.name }}
                </b-badge>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-12">
              <p class="text-muted">
                  {{ project?.description }}
              </p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-12">
              <a v-if="project?.link" :href="project.link" title="Enlace a la convocatoria" target="_blank">Enlace de la convocatoria</a>
              <!-- <a v-if="project?.link" :href="project.link" class="btn btn-primary btn-xs" title="Enlace a la convocatoria" target="_blank">
                <i class="mdi mdi-web font-size-18"> </i> Ver convocatoria
              </a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <EssayTable :fields="tableFields" :id="id" :rows="essay.length" />
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>


    <!-- <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-lg-12">
                <div class="text-center">
                  <h4>{{ project.name }}</h4>
                  <p class="text-muted">
                    {{ project.description }}
                  </p>
                  <p class="text-muted">
                    {{ project?.project_type?.name }}
                  </p>
                  <div>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="product-desc">
                    <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
                      <b-tab title="Redacción">
                        <div>
                          
                        </div>
                      </b-tab>
                      <b-tab title="Seguimiento">
                        <div>
                          <TrackingTable  />
                        </div>
                      </b-tab>
                      <b-tab title="Continuidad">
                      </b-tab>
                      <b-tab title="Adhesiones">
                      </b-tab>
                      <b-tab title="Renuncia">
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- end row -->
  </Layout>
</template>
